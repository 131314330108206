import ParentSize from "@visx/responsive/lib/components/ParentSize";
import * as React from "react";
import { Flex, Grid, Text } from "theme-ui";
import { theme } from "../../../themes/base";
import { formatPercentage } from "../../../utils";
import { Bars, BarStacks } from "../../Chart";
import { ErrorWidget } from "../../ErrorWidget";
import { SectionHeader } from "../../SectionHeader";
import { StyledCard } from "../../StyledCard";
import { Tooltip } from "../../Tooltip";

export type EngagementMetricsProps = {
  emailClickRate: any;
  emailOpenRate: any;
  commsClickRate: any;
  commsOpenRate: any;
  commsClickRateMTD: any;
  commsOpenRateMTD: any;
  paymentTrends: any;
  emailClickRateMTD: any;
  emailOpenRateMTD: any;
  paymentRate: any;
  paymentRateMTD: any;
  placementsAndRetractions: any;
  scrollRef: React.RefObject<HTMLInputElement>;
  smsClickRate: any;
  smsClickRateMTD: any;
  smsDeliveryRate: any;
  smsDeliveryRateMTD: any;
  smsEnabled: boolean;
  uniqueAccountClickRateMTD: any;
  uniqueAccountSendsAndClick: any;
  vmEnabled: boolean;
  vmDeliveryRate: any;
  vmDeliveryRateMTD: any;
  vmOptOutRate: any;
};

export function EngagementMetrics({
  emailClickRate,
  emailOpenRate,
  commsClickRate,
  commsOpenRate,
  commsClickRateMTD,
  commsOpenRateMTD,
  paymentTrends,
  emailClickRateMTD,
  emailOpenRateMTD,
  paymentRate,
  paymentRateMTD,
  placementsAndRetractions,
  scrollRef,
  smsClickRate,
  smsClickRateMTD,
  smsDeliveryRate,
  smsDeliveryRateMTD,
  smsEnabled = false,
  uniqueAccountClickRateMTD,
  uniqueAccountSendsAndClick,
  vmEnabled = false,
  vmDeliveryRate,
  vmDeliveryRateMTD,
  vmOptOutRate,
}: EngagementMetricsProps) {
  return (
    <div
      style={{ scrollMarginTop: theme.layout.scrollMarginTop }}
      id="engagement-metrics"
      ref={scrollRef}
    >
      <Grid>
        <SectionHeader>
          <Text variant="sectionTitle">Engagement Metrics</Text>
          <Tooltip
            position="bottom"
            accessibilityLabel="What are the Engagement Metrics?"
          >
            This section shows the monthly trends of Email engagement.
          </Tooltip>
        </SectionHeader>
      </Grid>

      <Grid
        sx={{
          p: 4,
          gridGap: 4,
          gridTemplateColumns: "repeat(3, 1fr)",
          "@media screen and (max-width: 64em)": {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
        }}
      >
        {/* CARD - EMAIL OPEN RATE MTD */}
        <StyledCard sx={{ minHeight: "8rem" }}>
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
            }}
          >
            {!emailOpenRateMTD?.error ? (
              <Text variant="widgetheader">
                {formatPercentage(1, emailOpenRateMTD?.value)}
              </Text>
            ) : (
              <ErrorWidget />
            )}
            <Text variant="widgetbody">Email Open Rate (MTD)</Text>
          </Flex>
        </StyledCard>

        {/* CARD - EMAIL CLICK RATE MTD */}
        <StyledCard sx={{ minHeight: "8rem" }}>
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
            }}
          >
            {!emailClickRateMTD?.error ? (
              <Text variant="widgetheader">
                {formatPercentage(1, emailClickRateMTD?.value)}
              </Text>
            ) : (
              <ErrorWidget />
            )}
            <Text variant="widgetbody">Email Click Rate (MTD)</Text>
          </Flex>
        </StyledCard>

        {/* CARD - PAYMENT RATE MTD */}
        <StyledCard sx={{ minHeight: "8rem" }}>
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
            }}
          >
            {!paymentRateMTD?.error ? (
              <Text variant="widgetheader">
                {formatPercentage(1, paymentRateMTD?.value)}
              </Text>
            ) : (
              <ErrorWidget />
            )}
            <Text variant="widgetbody">Payment Rate (MTD)</Text>
          </Flex>
        </StyledCard>
      </Grid>

      <Grid
        sx={{
          p: 4,
          gridGap: 4,
          gridTemplateColumns: "repeat(3, 1fr)",
          paddingTop: 0,
          "@media screen and (max-width: 64em)": {
            gridTemplateColumns: "repeat(1, 1fr)",
          },
        }}
      >
        {/* CARD - ACCOUNT EMAIL OPEN RATE */}
        <StyledCard
          sx={{
            height: "400px",
            overflow: "hidden",
            position: "relative",
            width: "100%",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
              height: "300px",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Account Email Open Rate
            </Text>
            {!emailOpenRate?.error ? (
              <ParentSize>
                {({ width, height }) => {
                  return (
                    <BarStacks
                      height={height}
                      width={width}
                      data={emailOpenRate?.value}
                      unit="percentage"
                      xUnit="percentage"
                      yUnit="percentage"
                      barColor={theme.colors.primary}
                      lineColor={theme.colors.primary}
                    />
                  );
                }}
              </ParentSize>
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>

        {/* CARD - ACCOUNT EMAIL CLICK RATE */}
        <StyledCard
          sx={{
            height: "400px",
            overflow: "hidden",
            position: "relative",
            width: "100%",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
              height: "300px",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Account Email Click Rate
            </Text>
            {!emailClickRate?.error ? (
              <ParentSize>
                {({ width, height }) => {
                  return (
                    <BarStacks
                      height={height}
                      width={width}
                      data={emailClickRate?.value}
                      unit="percentage"
                      xUnit="percentage"
                      yUnit="percentage"
                      barColor={theme.colors.retainViolet}
                      lineColor={theme.colors.retainViolet}
                    />
                  );
                }}
              </ParentSize>
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>

        {/* CARD - PAYMENT RATE */}
        <StyledCard
          sx={{
            height: "400px",
            overflow: "hidden",
            position: "relative",
            width: "100%",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
              height: "300px",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Payment Rate
            </Text>
            {!paymentRate?.error ? (
              <ParentSize>
                {({ width, height }) => {
                  return (
                    <BarStacks
                      height={height}
                      width={width}
                      data={paymentRate?.value}
                      unit="percentage"
                      xUnit="percentage"
                      yUnit="percentage"
                      barColor={theme.colors.secondaryGreen}
                      lineColor={theme.colors.secondaryGreen}
                    />
                  );
                }}
              </ParentSize>
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>
      </Grid>

      {smsEnabled && (
        <>
          <Grid
            sx={{
              p: 4,
              gridGap: 4,
              gridTemplateColumns: "repeat(3, 1fr)",
              paddingTop: 0,
              "@media screen and (max-width: 64em)": {
                gridTemplateColumns: "repeat(2, 1fr)",
              },
            }}
          >
            {/* CARD - SMS DELIVER RATE MTD */}
            <StyledCard sx={{ minHeight: "8rem" }}>
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                }}
              >
                {!smsDeliveryRateMTD?.error ? (
                  <Text variant="widgetheader">
                    {formatPercentage(1, smsDeliveryRateMTD?.value)}
                  </Text>
                ) : (
                  <ErrorWidget />
                )}
                <Text variant="widgetbody">SMS Deliver Rate (MTD)</Text>
              </Flex>
            </StyledCard>

            {/* CARD - SMS CLICK RATE MTD */}
            <StyledCard sx={{ minHeight: "8rem" }}>
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                }}
              >
                {!smsClickRateMTD?.error ? (
                  <Text variant="widgetheader">
                    {formatPercentage(1, smsClickRateMTD?.value)}
                  </Text>
                ) : (
                  <ErrorWidget />
                )}
                <Text variant="widgetbody">SMS Click Rate (MTD)</Text>
              </Flex>
            </StyledCard>

            {/* CARD - UNIQUE ACCOUNT CLICK RATE MTD */}
            <StyledCard sx={{ minHeight: "8rem" }}>
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                }}
              >
                {!uniqueAccountClickRateMTD?.error ? (
                  <Text variant="widgetheader">
                    {formatPercentage(1, uniqueAccountClickRateMTD?.value)}
                  </Text>
                ) : (
                  <ErrorWidget />
                )}
                <Text variant="widgetbody">
                  Unique Account Click Rate (MTD)
                </Text>
              </Flex>
            </StyledCard>
          </Grid>

          <Grid
            sx={{
              p: 4,
              gridGap: 4,
              gridTemplateColumns: "repeat(3, 1fr)",
              paddingTop: 0,
              "@media screen and (max-width: 64em)": {
                gridTemplateColumns: "repeat(1, 1fr)",
              },
            }}
          >
            {/* CARD - ACCOUNT EMAIL OPEN RATE */}
            <StyledCard
              sx={{
                height: "400px",
                overflow: "hidden",
                position: "relative",
                width: "100%",
              }}
            >
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                  height: "300px",
                }}
              >
                <Text
                  variant="widgettitle"
                  sx={{
                    position: "absolute",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  SMS Delivery Rate
                </Text>
                {!smsDeliveryRate?.error ? (
                  <ParentSize>
                    {({ width, height }) => {
                      return (
                        <BarStacks
                          height={height}
                          width={width}
                          data={smsDeliveryRate?.value}
                          unit="percentage"
                          xUnit="percentage"
                          yUnit="percentage"
                          barColor={theme.colors.primary}
                          lineColor={theme.colors.primary}
                        />
                      );
                    }}
                  </ParentSize>
                ) : (
                  <ErrorWidget />
                )}
              </Flex>
            </StyledCard>

            {/* CARD - SMS CLICK RATE */}
            <StyledCard
              sx={{
                height: "400px",
                overflow: "hidden",
                position: "relative",
                width: "100%",
              }}
            >
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                  height: "300px",
                }}
              >
                <Text
                  variant="widgettitle"
                  sx={{
                    position: "absolute",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  SMS Click Rate
                </Text>
                {!smsClickRate?.error ? (
                  <ParentSize>
                    {({ width, height }) => {
                      return (
                        <BarStacks
                          height={height}
                          width={width}
                          data={smsClickRate?.value}
                          unit="percentage"
                          xUnit="percentage"
                          yUnit="percentage"
                          barColor={theme.colors.retainViolet}
                          lineColor={theme.colors.retainViolet}
                        />
                      );
                    }}
                  </ParentSize>
                ) : (
                  <ErrorWidget />
                )}
              </Flex>
            </StyledCard>

            {/* CARD - UNIQUE ACCOUNT SENDS AND CLICK RATE */}
            <StyledCard
              sx={{
                height: "400px",
                overflow: "hidden",
                position: "relative",
                width: "100%",
              }}
            >
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                  height: "300px",
                }}
              >
                <Text
                  variant="widgettitle"
                  sx={{
                    position: "absolute",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  Unique Account Sends and Click Rate
                </Text>
                {!uniqueAccountSendsAndClick?.error ? (
                  <ParentSize>
                    {({ width, height }) => {
                      return (
                        <BarStacks
                          height={height}
                          width={width}
                          data={uniqueAccountSendsAndClick?.value?.all}
                          xUnit="number"
                          yUnit="number"
                          yUnitLinePath="percentage"
                          barColor={theme.colors.primary}
                          lineColor={theme.colors.tertiary}
                        />
                      );
                    }}
                  </ParentSize>
                ) : (
                  <ErrorWidget />
                )}
              </Flex>
            </StyledCard>
          </Grid>

          <Grid
            sx={{
              p: 4,
              gridGap: 4,
              gridTemplateColumns: "repeat(2, 1fr)",
              paddingTop: 0,
              "@media screen and (max-width: 64em)": {
                gridTemplateColumns: "repeat(2, 1fr)",
              },
            }}
          >
            {/* CARD - COMMS OPEN RATE MTD */}
            <StyledCard sx={{ minHeight: "8rem" }}>
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                }}
              >
                {!commsOpenRateMTD?.error ? (
                  <Text variant="widgetheader">
                    {formatPercentage(1, commsOpenRateMTD?.value)}
                  </Text>
                ) : (
                  <ErrorWidget />
                )}
                <Text variant="widgetbody">Comms Open Rate (MTD)</Text>
              </Flex>
            </StyledCard>

            {/* CARD - COMMS CLICK RATE MTD */}
            <StyledCard sx={{ minHeight: "8rem" }}>
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                }}
              >
                {!commsClickRateMTD?.error ? (
                  <Text variant="widgetheader">
                    {formatPercentage(1, commsClickRateMTD?.value)}
                  </Text>
                ) : (
                  <ErrorWidget />
                )}
                <Text variant="widgetbody">Comms Click Rate (MTD)</Text>
              </Flex>
            </StyledCard>
          </Grid>

          <Grid
            sx={{
              p: 4,
              gridGap: 4,
              gridTemplateColumns: "repeat(2, 1fr)",
              paddingTop: 0,
              "@media screen and (max-width: 64em)": {
                gridTemplateColumns: "repeat(1, 1fr)",
              },
            }}
          >
            {/* CARD - ACCOUNT TOTAL COMMS OPEN RATE */}
            <StyledCard
              sx={{
                height: "400px",
                overflow: "hidden",
                position: "relative",
                width: "100%",
              }}
            >
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                  height: "300px",
                }}
              >
                <Text
                  variant="widgettitle"
                  sx={{
                    position: "absolute",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  Account Total Comms Open Rate
                </Text>
                {!commsOpenRate?.error ? (
                  <ParentSize>
                    {({ width, height }) => {
                      return (
                        <BarStacks
                          height={height}
                          width={width}
                          data={commsOpenRate?.value}
                          unit="percentage"
                          xUnit="percentage"
                          yUnit="percentage"
                          barColor={theme.colors.primary}
                          lineColor={theme.colors.primary}
                        />
                      );
                    }}
                  </ParentSize>
                ) : (
                  <ErrorWidget />
                )}
              </Flex>
            </StyledCard>

            {/* CARD - ACCOUNT TOTAL COMMS CLICK RATE */}
            <StyledCard
              sx={{
                height: "400px",
                overflow: "hidden",
                position: "relative",
                width: "100%",
              }}
            >
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                  height: "300px",
                }}
              >
                <Text
                  variant="widgettitle"
                  sx={{
                    position: "absolute",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  Account Total Comms Click Rate
                </Text>
                {!commsClickRate?.error ? (
                  <ParentSize>
                    {({ width, height }) => {
                      return (
                        <BarStacks
                          height={height}
                          width={width}
                          data={commsClickRate?.value}
                          unit="percentage"
                          xUnit="percentage"
                          yUnit="percentage"
                          barColor={theme.colors.retainViolet}
                          lineColor={theme.colors.retainViolet}
                        />
                      );
                    }}
                  </ParentSize>
                ) : (
                  <ErrorWidget />
                )}
              </Flex>
            </StyledCard>
          </Grid>
        </>
      )}

      {vmEnabled && (
        <>
          <Grid
            sx={{
              p: 4,
              gridGap: 4,
              gridTemplateColumns: "repeat(2, 1fr)",
              paddingTop: 0,
              "@media screen and (max-width: 64em)": {
                gridTemplateColumns: "repeat(2, 1fr)",
              },
            }}
          >
            {/* CARD - VM DROP DELIVER RATE (MTD) */}
            <StyledCard sx={{ minHeight: "8rem" }}>
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                }}
              >
                {!vmDeliveryRateMTD?.error ? (
                  <Text variant="widgetheader">
                    {formatPercentage(1, vmDeliveryRateMTD?.value)}
                  </Text>
                ) : (
                  <ErrorWidget />
                )}
                <Text variant="widgetbody">VM Drop Deliver Rate (MTD)</Text>
              </Flex>
            </StyledCard>

            {/* CARD - ACCOUNT VM OPT OUT RATE */}
            <StyledCard sx={{ minHeight: "8rem" }}>
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                }}
              >
                {!vmOptOutRate?.error ? (
                  <Text variant="widgetheader">
                    {formatPercentage(1, vmOptOutRate?.value)}
                  </Text>
                ) : (
                  <ErrorWidget />
                )}
                <Text variant="widgetbody">Account VM Opt Out Rate</Text>
              </Flex>
            </StyledCard>
          </Grid>

          <Grid
            sx={{
              p: 4,
              gridGap: 4,
              gridTemplateColumns: "repeat(1, 1fr)",
              paddingTop: 0,
              "@media screen and (max-width: 64em)": {
                gridTemplateColumns: "repeat(1, 1fr)",
              },
            }}
          >
            {/* CARD - VM ACCOUNT DELIVERED BY SENT RATE */}
            <StyledCard
              sx={{
                height: "400px",
                overflow: "hidden",
                position: "relative",
                width: "100%",
              }}
            >
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                  height: "300px",
                }}
              >
                <Text
                  variant="widgettitle"
                  sx={{
                    position: "absolute",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  VM Account Delivered by Sent Rate
                </Text>
                {!vmDeliveryRate?.error ? (
                  <ParentSize>
                    {({ width, height }) => {
                      return (
                        <BarStacks
                          height={height}
                          width={width}
                          data={vmDeliveryRate?.value}
                          unit="percentage"
                          xUnit="percentage"
                          yUnit="percentage"
                          barColor={theme.colors.primary}
                          lineColor={theme.colors.primary}
                        />
                      );
                    }}
                  </ParentSize>
                ) : (
                  <ErrorWidget />
                )}
              </Flex>
            </StyledCard>
          </Grid>
        </>
      )}

      <Grid
        sx={{
          p: 4,
          gridGap: 4,
          gridTemplateColumns: "repeat(1, 1fr)",
          paddingTop: 0,
          "@media screen and (max-width: 64em)": {
            gridTemplateColumns: "repeat(1, 1fr)",
          },
        }}
      >
        {/* CARD - DAILY PAYMENT TRENDS (PAST 45 DAYS) */}
        <StyledCard
          sx={{
            height: "400px",
            overflow: "hidden",
            position: "relative",
            width: "100%",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
              height: "300px",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Daily Payment Trends in the Past 45 Days
            </Text>
            {!paymentTrends?.error ? (
              <ParentSize>
                {({ width, height }) => {
                  return (
                    <BarStacks
                      height={height}
                      width={width}
                      data={paymentTrends?.value?.all}
                      xUnit="number"
                      yUnit="number"
                      barColor={theme.colors.secondaryGreen}
                      lineColor={theme.colors.primary}
                    />
                  );
                }}
              </ParentSize>
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>
      </Grid>

      <Grid
        sx={{
          gridGap: 4,
          p: 4,
          paddingTop: 0,
          gridTemplateColumns: "1fr",
          gridAutoFlow: "row",
        }}
      >
        {/* CARD - PLACEMENTS AND RETRACTIONS (PAST 45 DAYS) */}
        <StyledCard
          sx={{
            minHeight: "400px",
            width: "100%",
            position: "relative",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              height: "400px",
              textAlign: "center",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Placements &amp; Retractions in the Past 45 Days
            </Text>
            {!placementsAndRetractions?.error ? (
              <ParentSize>
                {({ width, height }) => {
                  return (
                    <Bars
                      height={height}
                      width={width}
                      data={placementsAndRetractions?.value}
                    />
                  );
                }}
              </ParentSize>
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>
      </Grid>
    </div>
  );
}
