import * as React from "react";
import { Flex } from "theme-ui";
import { theme } from "../../themes/base";

export type SectionHeaderProps = {
  children: React.ReactNode;
};

export function SectionHeader(props: SectionHeaderProps) {
  return (
    <Flex
      sx={{
        backgroundColor: theme.colors.retainViolet,
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        flexFlow: "row",
        textAlign: "center",
        padding: "1rem",
      }}
    >
      {props.children}
    </Flex>
  );
}
