import * as React from "react";
import { Card, CardProps } from "theme-ui";

export function StyledCard(props: CardProps) {
  return (
    <Card
      sx={{
        ...props.sx,
        display: "grid",
      }}
    >
      {props.children}
    </Card>
  );
}
