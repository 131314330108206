import { Annotation, Connector, Label } from "@visx/annotation";
import { Group } from "@visx/group";
import { LegendOrdinal } from "@visx/legend";
import { scaleOrdinal } from "@visx/scale";
import Pie, { ProvidedProps, PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import { Text } from "@visx/text";
import { format as d3Format } from "d3-format";
import { Arc as ArcType } from "d3-shape";
import React, { Dispatch, SetStateAction, useState } from "react";
import { colorRange } from "../../src/helpers";

// data and types
type UnitType = "currency" | "percentage" | "number" | "simple";

const formatToSimpleCurrency = d3Format("$,.3~s");
const formatToSimpleNumber = d3Format(".3~s");

// accessor functions
const getX = (d: any) => d.xValue;

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

export type PieChartProps = {
  data: any;
  unit?: UnitType;
  mode?: "donut" | "pie";
  width: number;
  height: number;
  margin?: typeof defaultMargin;
};

export function PieChart({
  data,
  unit,
  mode = "pie",
  width,
  height,
  margin = defaultMargin,
}: PieChartProps) {
  const [donutTitle, setDonutTitle] = useState("");
  const [donutTitleColor, setDonutTitleColor] = useState("");
  const keys = data.map((d: any) => d.yValue);
  keys.sort(isNaN(keys[0]) ? undefined : (a: number, b: number) => a - b);

  // color scale
  const colorScale = scaleOrdinal({
    domain: keys,
    range: colorRange,
  });
  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const radius = Math.min(innerWidth, innerHeight) / 3;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 50;
  const topMargin = keys.length > 4 ? 0 : 16;

  return (
    <div>
      <svg width={width} height={height}>
        <Group
          top={centerY + margin.top + topMargin}
          left={centerX + margin.left}
        >
          <Pie
            data={data}
            pieValue={getX}
            outerRadius={radius}
            cornerRadius={3}
            padRadius={30}
            {...(mode === "donut" && {
              innerRadius: radius - donutThickness,
            })}
          >
            {(pie) => (
              <PieArcs
                {...pie}
                unit={unit}
                getKey={(arc) => arc.data.yValue}
                getColor={(arc) => colorScale(arc.data.yValue)}
                setDonutTitle={setDonutTitle}
                setDonutTitleColor={setDonutTitleColor}
              />
            )}
          </Pie>
          {mode !== "pie" && (
            <Text
              textAnchor="middle"
              fill={donutTitleColor}
              fontSize={15}
              fontWeight="bold"
            >
              {donutTitle}
            </Text>
          )}
        </Group>
      </svg>
      <div
        style={{
          position: "absolute",
          bottom: margin.bottom - (keys.length > 4 ? 20 : 5),
          width: "100%",
          display: "flex",
          justifyContent: "center",
          fontSize: "clamp(0.8rem, 2vw, 1rem)",
        }}
      >
        <LegendOrdinal
          style={{
            flexWrap: "wrap",
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
          }}
          scale={colorScale}
          direction="row"
          labelMargin="0 1rem 0 0"
        />
      </div>
    </div>
  );
}

// Components helpers

type AnimatedPieChartProps<Datum> = ProvidedProps<Datum> & {
  setDonutTitle: Dispatch<SetStateAction<string>>;
  setDonutTitleColor: Dispatch<SetStateAction<string>>;
  unit?: UnitType;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  onClickDatum?: (d: PieArcDatum<Datum>) => void;
};

function PieArcs<Datum>(props: AnimatedPieChartProps<Datum>) {
  const {
    setDonutTitle,
    setDonutTitleColor,
    unit,
    arcs,
    path,
    getKey,
    getColor,
  } = props;

  return (
    <g>
      {arcs.map((arc) => (
        <PieArc
          setDonutTitle={setDonutTitle}
          setDonutTitleColor={setDonutTitleColor}
          unit={unit}
          key={getKey(arc)}
          arc={arc}
          path={path}
          getColor={getColor}
        />
      ))}
    </g>
  );
}

interface PieArcProps<Datum> {
  setDonutTitle: Dispatch<SetStateAction<string>>;
  setDonutTitleColor: Dispatch<SetStateAction<string>>;
  unit?: UnitType;
  getColor: (d: PieArcDatum<Datum>) => string;
  path: ArcType<any, PieArcDatum<Datum>>;
  arc: PieArcDatum<Datum>;
}

function PieArc<Datum>(props: PieArcProps<Datum>) {
  const { setDonutTitle, setDonutTitleColor, unit, path, arc, getColor } =
    props;
  const displayAnnotation = arc.endAngle - arc.startAngle > 0.05;
  const pathValue = path(arc) ?? "";
  const middleAngle =
    Math.PI / 2 - (arc.startAngle + (arc.endAngle - arc.startAngle) / 2);

  const radius = path.outerRadius()(arc);
  const normalX = Math.cos(middleAngle);
  const normalY = Math.sin(-middleAngle);

  const labelX = normalX * 15;
  const labelY = normalY * 15;

  const surfaceX = normalX * (radius + 2);
  const surfaceY = normalY * (radius + 2);
  const title =
    unit === "currency"
      ? formatToSimpleCurrency(arc.value)
      : formatToSimpleNumber(arc.value);

  return (
    <g>
      <path
        d={pathValue}
        fill={getColor(arc)}
        stroke={"white"}
        strokeWidth={1}
        onMouseEnter={() => {
          setDonutTitle(title);
          setDonutTitleColor(getColor(arc));
        }}
        onMouseLeave={() => setDonutTitle("")}
      />
      {displayAnnotation && (
        <>
          <Annotation x={surfaceX} y={surfaceY} dx={labelX} dy={labelY}>
            <Connector type={"line"} stroke={getColor(arc)} />
            <Label
              showBackground={false}
              showAnchorLine={false}
              title={title}
              titleFontSize="12px"
              titleFontWeight="bold"
              fontColor={getColor(arc)}
            />
          </Annotation>

          <circle
            r={4}
            fill={getColor(arc)}
            cx={surfaceX + labelX}
            cy={surfaceY + labelY}
          />
        </>
      )}
    </g>
  );
}
