import * as React from "react";
import { Text } from "theme-ui";

export type ErrorWidgetProps = {
  h100?: boolean;
};

export function ErrorWidget({ h100 = false }: ErrorWidgetProps) {
  return (
    <Text
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        ...(h100 && { height: "100%" }),
      }}
      variant="noDataTitle"
    >
      NO DATA
    </Text>
  );
}
