import * as allCurves from "@visx/curve";
import { localPoint } from "@visx/event";
import { Grid } from "@visx/grid";
import { Group } from "@visx/group";
import { LegendOrdinal } from "@visx/legend";
import { MarkerCircle } from "@visx/marker";
import { DateValue } from "@visx/mock-data/lib/generators/genDateValue";
import { AnimatedAxis } from "@visx/react-spring";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { BarStack, Line, LinePath } from "@visx/shape";
import { SeriesPoint } from "@visx/shape/lib/types";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { format as d3Format } from "d3-format";
import { timeParse, timeFormat } from "d3-time-format";
import React from "react";
import {
  formattedNumber,
  formattedPercentage,
  formattedCurrency,
} from "../../src/helpers";
import { filterKeyFromObjects, formatPercentage } from "../../utils";
import { compose } from "../../utils/compose";
import { ErrorWidget } from "../ErrorWidget";

type UnitType = "currency" | "percentage" | "number" | "simple";

type TooltipData = {
  bar: SeriesPoint<any> | any;
  color: string;
  graphType?: string;
  height: number;
  index: number;
  key: string;
  width: number;
  x: number;
  y: number;
};

export type BarStackProps = {
  barColor: string;
  lineColor: string;
  data: any;
  events?: boolean;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  numTicks?: number;
  unit?: UnitType;
  width: number;
  xUnit?: UnitType;
  yUnit?: UnitType;
  yUnitLinePath?: UnitType;
};

type CurveType = keyof typeof allCurves;

function scaleBandInvert(scale: any) {
  var domain = scale.domain();
  var paddingOuter = scale(domain[0]);
  var eachBand = scale.step();
  return function (value: any) {
    var index = Math.floor((value - paddingOuter) / eachBand);
    return domain[Math.max(0, Math.min(index, domain.length - 1))];
  };
}

const curveTypes = Object.keys(allCurves);

const parseMonth = timeParse("%Y-%m");
const parseDate = timeParse("%Y-%m-%d");
const format = timeFormat("%x");

// @see https://observablehq.com/@camdecoster/d3-tester
// const formatToCurrency = d3Format("$,");
// const formatToPercent = d3Format(",.1%");
const formatToSimpleCurrency = d3Format("$,.3~s");
const formatToSimpleNumber = d3Format(".3~s");

const formatDate = (date: string) => format(parseDate(date) as Date);
const formatMonth = (date: string) => format(parseMonth(date) as Date);
// const formatCurrency = (value: number) => formatToCurrency(value);
const formatSimpleCurrency = (value: number) => formatToSimpleCurrency(value);
// const formatPercent = (value: number) => formatToPercent(value);

// accessors
const getDate = (d: any) => d.date;
const getMonth = (d: any) => d.month;

export function BarStacks({
  barColor,
  lineColor,
  data = [],
  height,
  margin = { top: 32, right: 40, bottom: 32, left: 40 },
  width,
  unit,
  xUnit,
  yUnit,
  yUnitLinePath,
  numTicks,
}: BarStackProps) {
  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: "#EEE",
    color: "#000",
  };

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom - 96;

  let tooltipTimeout: number;

  const value = (d: any) => d.y;

  const filterX = (arr: any): any => filterKeyFromObjects("x", arr);
  const filterY = (arr: any): any => filterKeyFromObjects("y", arr);
  const filterY0 = (arr: any): any => filterKeyFromObjects("y0", arr);
  const filterY1 = (arr: any): any => filterKeyFromObjects("y1", arr);
  const filterX0 = (arr: any): any => filterKeyFromObjects("x0", arr);
  const filterX1 = (arr: any): any => filterKeyFromObjects("x1", arr);
  const filteredDataWithoutCoords = compose(
    filterX,
    filterX0,
    filterX1,
    filterY,
    filterY0,
    filterY1
  );
  const dataWithoutCoords = filteredDataWithoutCoords(data);

  const [curveType, setCurveType] = React.useState<CurveType>("curveLinear");

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  const handleLinePathTooltip = (event: any) => {
    const { x, y } = localPoint(event) || { x: 0, y: 0 };
    const x0 = scaleBandInvert(isMonthly ? monthScale : dateScale)(
      x - margin.left
    ); // get Date from the scale
    const point = data.find((z: any) =>
      isMonthly ? z.month === x0 : z.date === x0
    ); // bisectDate(data, x0, 1); // get index of this date from the array
    showTooltip({
      tooltipData: {
        bar: { data: point },
        color: "",
        graphType: "line",
        height: 0,
        index: 0,
        key: point.line0,
        width: 0,
        x,
        y,
      },
      tooltipLeft: x,
      tooltipTop: y,
    });
  };

  const keys = Object.keys(dataWithoutCoords[0] || []).filter(
    (d) => d !== "date" && d !== "month" && d !== "line0"
  ) as any[];

  const isMonthly = Object.keys(dataWithoutCoords[0] || []).includes("month");

  const values = dataWithoutCoords.reduce(
    (allTotals: any, currentDate: any) => {
      const totalCount = keys.reduce((dailyTotal, k) => {
        dailyTotal += Number(currentDate[k]);
        return dailyTotal;
      }, 0);
      allTotals.push(totalCount);
      return allTotals;
    },
    [] as number[]
  );

  const secondaryDatum = data.reduce((arr: any, current: any) => {
    current.y && arr.push(current.y);
    return arr;
  }, [] as number[]);

  const defaultMaxDomainValue = unit === "percentage" ? 1 : 1000;
  const maxLineDomainValue = Math.max(...secondaryDatum);
  const setDefaultMaxLineDomainValue =
    maxLineDomainValue === 0 && unit !== "currency" && unit !== "simple";

  // scales
  const xScale = scaleBand({
    range: [0, xMax],
    domain: dataWithoutCoords.map(isMonthly ? getMonth : getDate),
    paddingInner: 0.2,
    paddingOuter: 0.1,
    round: true,
  });

  const yScale = scaleLinear({
    range: [0, yMax],
    domain: [
      setDefaultMaxLineDomainValue
        ? defaultMaxDomainValue
        : (maxLineDomainValue as number),
      0,
    ],
  });

  const dateScale = scaleBand<string>({
    domain: dataWithoutCoords.map(getDate),
    paddingInner: 0.2,
    paddingOuter: 0.1,
    round: true,
  });

  const monthScale = scaleBand<string>({
    domain: dataWithoutCoords.map(getMonth),
    padding: 0,
    paddingInner: 0.2,
    paddingOuter: 0.1,
    round: true,
  });

  const maxDomainValueScale = Math.max(...values, Math.max(...values));
  const setDefaultMaxDomainValue =
    maxDomainValueScale === 0 && unit !== "currency" && unit !== "simple";
  const valueScale = scaleLinear<number>({
    domain: [
      0,
      setDefaultMaxDomainValue ? defaultMaxDomainValue : maxDomainValueScale,
    ],
    nice: true,
  });

  const colorScale = scaleOrdinal<any, string>({
    domain: keys,
    range: [barColor, lineColor],
  });

  const keyScale = scaleBand<string>({
    domain: keys,
    padding: 0,
  });

  dateScale.rangeRound([0, xMax]);
  monthScale.rangeRound([0, xMax]);
  valueScale.range([yMax, 0]);
  keyScale.rangeRound([
    0,
    isMonthly ? monthScale.bandwidth() : dateScale.bandwidth(),
  ]);

  // positions
  const getX = (d: DateValue) => {
    const scaleValue = xScale(isMonthly ? getMonth(d) : getDate(d)) ?? 0;

    return xScale.bandwidth() / 2.0 + scaleValue;
  };

  const getY = (d: DateValue) => yScale(value(d)) ?? 0;

  if (!data || data.length < 1) {
    return <ErrorWidget h100 />;
  }

  if (width < 10) return null;

  return (
    <div style={{ position: "relative" }}>
      <div style={{ marginTop: "1.5rem" }}>
        {false && (
          <label>
            Curve type &nbsp;
            <select
              onChange={(e) => setCurveType(e.target.value as CurveType)}
              value={curveType}
            >
              {curveTypes.map((curve) => (
                <option key={curve} value={curve}>
                  {curve}
                </option>
              ))}
            </select>
          </label>
        )}
      </div>
      <svg ref={containerRef} width={width} height={height}>
        <MarkerCircle
          id="marker-circle"
          fill={"white"}
          fillOpacity={0.5}
          size={2}
          strokeOpacity={0.5}
          stroke={lineColor}
          refX={2}
        />
        <Group left={margin.left} top={margin.top}>
          <Grid
            xScale={isMonthly ? monthScale : dateScale}
            yScale={valueScale}
            width={xMax}
            height={yMax}
            stroke={barColor}
            strokeOpacity={0.1}
          />
          <AnimatedAxis
            // 16px (base font size) * 32rem = width
            hideAxisLine
            hideTicks
            numTicks={numTicks}
            orientation="left"
            left={-24}
            scale={valueScale}
            stroke={barColor}
            strokeWidth={1}
            tickFormat={formatToSimpleNumber}
            {...(unit === "currency" && { tickFormat: formatSimpleCurrency })}
            {...(xUnit === "currency" && { tickFormat: formatSimpleCurrency })}
            {...(xUnit === "percentage" && {
              tickFormat: formatPercentage.bind(null, 2),
            })}
            {...(yUnit === "currency" && {
              tickFormat: formatSimpleCurrency,
            })}
            {...(yUnit === "percentage" && {
              tickFormat: formatPercentage.bind(null, 2),
            })}
            tickLabelProps={() => ({
              fill: barColor,
              fontSize: "clamp(0.5rem, 2vw, 0.6rem)",
            })}
          />

          <AnimatedAxis
            top={yMax}
            scale={isMonthly ? monthScale : dateScale}
            stroke={barColor}
            // 16px (base font size) * 32rem = width
            numTicks={width < 16 * 32 ? 8 : 12}
            tickFormat={isMonthly ? formatMonth : formatDate}
            tickStroke={barColor}
            tickLabelProps={() => ({
              fill: barColor,
              fontSize: "clamp(0.5rem, 2vw, 0.6rem)",
              textAnchor: "middle",
            })}
            orientation="bottom"
          >
            {(props) => {
              const tickLabelSize = 10;
              const tickRotate = -45;
              const tickColor = barColor;
              const axisCenter =
                (props.axisToPoint.x - props.axisFromPoint.x) / 2;
              return (
                <Group>
                  {props.ticks.map((tick, i) => {
                    const tickX = tick.to.x;
                    const tickY = tick.to.y + tickLabelSize;
                    return (
                      <Group
                        key={`vx-tick-${tick.value}-${i}`}
                        className={"vx-axis-tick"}
                      >
                        <Line from={tick.from} to={tick.to} stroke={barColor} />
                        <Text
                          transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                          fontSize={tickLabelSize}
                          textAnchor="end"
                          fill={tickColor}
                        >
                          {tick.formattedValue}
                        </Text>
                      </Group>
                    );
                  })}
                  <Text
                    textAnchor="middle"
                    transform={`translate(${axisCenter}, 50)`}
                    fontSize="clamp(0.5rem, 2vw, 0.6rem)"
                  >
                    {props.label}
                  </Text>
                </Group>
              );
            }}
          </AnimatedAxis>

          {/* Animated Axis for LinePath */}
          {keys.length > 1 && (
            <AnimatedAxis
              // 16px (base font size) * 32rem = width
              hideAxisLine
              hideTicks
              // labelOffset={124}
              // top={margin.top}
              left={xMax + 16}
              numTicks={width < 16 * 32 ? 6 : 10}
              orientation="left"
              scale={yScale}
              stroke={lineColor}
              strokeWidth={1}
              // default
              tickFormat={formatToSimpleNumber}
              {...(yUnitLinePath === "percentage" && {
                tickFormat: formatPercentage.bind(null, 2),
              })}
              // // unless unit is `currency`
              // {...(unit === "currency" && { tickFormat: formatSimpleCurrency })}
              // {...(xUnit === "currency" && { tickFormat: formatSimpleCurrency })}
              // {...(xUnit === "percentage" && {
              //   tickFormat: formatPercentage.bind(null, 2),
              // })}
              // {...(yUnit === "currency" && {
              //   tickFormat: formatSimpleCurrency,
              // })}
              tickLabelProps={() => ({
                fill: lineColor,
                fontSize: "clamp(0.5rem, 2vw, 0.6rem)",
              })}
            />
          )}

          <BarStack
            data={data}
            keys={keys}
            x={isMonthly ? getMonth : getDate}
            xScale={isMonthly ? monthScale : dateScale}
            yScale={valueScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) => (
                <React.Fragment key={`bar-stacks-frag-${barStack.index}`}>
                  {barStack.bars.map((bar) =>
                    data.some((d: any) => d?.line0 === bar.key) ? null : (
                      <Group
                        key={`bar-stack-frag-${barStack.index}-${bar.index}`}
                      >
                        <rect
                          key={`bar-stack-${barStack.index}-${bar.index}`}
                          x={bar.x}
                          y={bar.y}
                          width={bar.width}
                          height={bar.height}
                          fill={bar.color}
                          onMouseLeave={(event) => {
                            if (!window) return;
                            tooltipTimeout = window.setTimeout(() => {
                              hideTooltip();
                            }, 300);
                            event?.currentTarget?.style.setProperty(
                              "opacity",
                              "1"
                            );
                            event?.currentTarget?.style.setProperty(
                              "cursor",
                              "default"
                            );
                          }}
                          onMouseEnter={(event) => {
                            if (!window) return;
                            tooltipTimeout = window.setTimeout(() => {
                              hideTooltip();
                            }, 300);
                            event?.currentTarget.style.setProperty(
                              "opacity",
                              "1"
                            );
                            event?.currentTarget.style.setProperty(
                              "cursor",
                              "pointer"
                            );
                          }}
                          onMouseMove={(event) => {
                            if (!event) return;
                            if (tooltipTimeout) clearTimeout(tooltipTimeout);
                            // TooltipInPortal expects coordinates to be relative to containerRef
                            // localPoint returns coordinates relative to the nearest SVG, which
                            // is what containerRef is set to in this example.
                            const eventSvgCoords = localPoint(event);
                            const left = bar.x + bar.width / 2;
                            event?.currentTarget?.style.setProperty(
                              "opacity",
                              "0.8"
                            );
                            event?.currentTarget?.style.setProperty(
                              "cursor",
                              "pointer"
                            );
                            showTooltip({
                              tooltipData: bar,
                              tooltipTop: eventSvgCoords?.y,
                              tooltipLeft: left,
                            });
                          }}
                        />

                        {bar.bar[0] === 0 && (
                          <Text
                            paintOrder={0}
                            fill={barColor}
                            fontSize="clamp(0.5rem, 2vw, 0.75rem)"
                            x={bar.x + bar.width / 2}
                            y={bar.y - 10}
                            dominantBaseline="middle"
                            textAnchor="middle"
                          >
                            {
                              {
                                number: formatToSimpleNumber(bar.bar[1]),
                                currency: formatToSimpleCurrency(bar.bar[1]),
                                percentage: formatPercentage(1, bar.bar[1]),
                                simple: formatToSimpleNumber(bar.bar[1]),
                              }[yUnit || "number"]
                            }
                          </Text>
                        )}
                      </Group>
                    )
                  )}
                </React.Fragment>
              ))
            }
          </BarStack>

          {keys.length > 1 && (
            <LinePath
              curve={allCurves[curveType]}
              data={data}
              markerMid="url(#marker-circle)"
              markerStart="url(#marker-circle)"
              markerEnd="url(#marker-circle)"
              x={getX}
              y={getY}
              shapeRendering="geometricPrecision"
              stroke={lineColor}
              strokeWidth={2}
              strokeOpacity={0.7}
              onMouseLeave={(event) => {
                if (!window) return;
                tooltipTimeout = window.setTimeout(() => {
                  hideTooltip();
                }, 300);
                event?.currentTarget?.style.setProperty("opacity", "1");
                event?.currentTarget?.style.setProperty("cursor", "default");
              }}
              onMouseEnter={(event) => {
                if (!window) return;
                tooltipTimeout = window.setTimeout(() => {
                  hideTooltip();
                }, 300);
                event?.currentTarget.style.setProperty("opacity", "1");
                event?.currentTarget.style.setProperty("cursor", "pointer");
              }}
              onMouseMove={handleLinePathTooltip}
            />
          )}
        </Group>
      </svg>

      <div
        style={{
          position: "absolute",
          bottom: margin.bottom,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          fontSize: "clamp(0.8rem, 2vw, 1rem)",
        }}
      >
        <LegendOrdinal
          scale={colorScale}
          direction="row"
          labelMargin="0 1rem 0 0"
        />
      </div>

      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div style={{ color: colorScale(tooltipData.key) }}>
            <strong>{tooltipData.key}</strong>
          </div>
          {tooltipData.bar && tooltipData.bar.data && (
            <div>
              <div>
                {yUnitLinePath === "percentage" &&
                  tooltipData.graphType === "line" &&
                  formattedPercentage(1, tooltipData.bar.data[tooltipData.key])}
                {yUnitLinePath === "currency" &&
                  tooltipData.graphType === "line" &&
                  formattedCurrency(tooltipData.bar.data[tooltipData.key])}
                {(!yUnitLinePath ||
                  yUnitLinePath === "number" ||
                  tooltipData.graphType !== "line") &&
                  formattedNumber(tooltipData.bar.data[tooltipData.key], 2)}
              </div>
            </div>
          )}
          {tooltipData.bar && tooltipData.bar.data && (
            <div>
              {isMonthly ? (
                <small>{formatMonth(getMonth(tooltipData.bar?.data))}</small>
              ) : (
                <small>{formatDate(getDate(tooltipData.bar?.data))}</small>
              )}
            </div>
          )}
        </TooltipInPortal>
      )}
    </div>
  );
}
